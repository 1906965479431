// Dark Theme

$custom-control-indicator-bg__dt:            $input-bg__dt !default;
$custom-control-indicator-border-color__dt:  rgba($black, .3) !default;
$custom-control-indicator-disabled-bg__dt:   $input-disabled-bg__dt !default;

$custom-control-label-disabled-color__dt:  $gray-600 !default;

$custom-control-indicator-checked-color__dt:         $component-active-color__dt !default;
$custom-control-indicator-checked-bg__dt:            $component-active-bg__dt !default;
$custom-control-indicator-checked-disabled-bg__dt:   rgba($primary__dt, .5) !default;
$custom-control-indicator-checked-border-color__dt:  $custom-control-indicator-checked-bg__dt !default;

$custom-control-indicator-focus-border-color__dt:  $input-focus-border-color__dt !default;

$custom-control-indicator-active-color__dt:         $component-active-color__dt !default;
$custom-control-indicator-active-bg__dt:            lighten($component-active-bg__dt, 35%) !default;
$custom-control-indicator-active-border-color__dt:  $custom-control-indicator-active-bg__dt !default;

$custom-checkbox-indicator-icon-checked__dt:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color__dt}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-bg__dt:            $component-active-bg__dt !default;
$custom-checkbox-indicator-indeterminate-color__dt:         $custom-control-indicator-checked-color__dt !default;
$custom-checkbox-indicator-indeterminate-border-color__dt:  $custom-checkbox-indicator-indeterminate-bg__dt !default;
$custom-checkbox-indicator-icon-indeterminate__dt:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color__dt}' d='M0 2h4'/></svg>") !default;

$custom-radio-indicator-icon-checked__dt:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color__dt}'/></svg>") !default;

$custom-select-color__dt:               $input-color__dt !default;
$custom-select-disabled-color__dt:      $gray-600 !default;
$custom-select-bg__dt:                  $input-bg__dt !default;
$custom-select-disabled-bg__dt:         $input-disabled-bg__dt !default;
$custom-select-indicator-color__dt:     $gray-800 !default;
$custom-select-indicator__dt:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color__dt}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") !default;
$custom-select-background__dt:          escape-svg($custom-select-indicator__dt) no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)
$custom-select-border-color__dt:        rgba($black, .3) !default;
$custom-select-focus-border-color__dt:  $input-focus-border-color__dt !default;

$custom-range-track-bg__dt:           $gray-300 !default;
$custom-range-thumb-bg__dt:           $component-active-bg__dt !default;
$custom-range-thumb-active-bg__dt:    lighten($component-active-bg__dt, 35%) !default;
$custom-range-thumb-disabled-bg__dt:  $gray-500 !default;

$custom-file-focus-border-color__dt:  $input-focus-border-color__dt !default;
$custom-file-disabled-bg__dt:         $input-disabled-bg__dt !default;
$custom-file-color__dt:               $input-color__dt !default;
$custom-file-bg__dt:                  $input-bg__dt !default;
$custom-file-border-color__dt:        rgba($black, .3) !default;
$custom-file-button-color__dt:        $custom-file-color__dt !default;
$custom-file-button-bg__dt:           $input-group-addon-bg__dt !default;


// stylelint-disable-next-line scss/dollar-variable-default
$custom-forms-theme-map: map-merge(
  $custom-forms-theme-map,
  (
    dark: (
      "custom-control-indicator-bg":                           $custom-control-indicator-bg__dt,
      "custom-control-indicator-border-color":                 $custom-control-indicator-border-color__dt,
      "custom-control-indicator-disabled-bg":                  $custom-control-indicator-disabled-bg__dt,
      "custom-control-label-disabled-color":                   $custom-control-label-disabled-color__dt,
      "custom-control-indicator-checked-color":                $custom-control-indicator-checked-color__dt,
      "custom-control-indicator-checked-bg":                   $custom-control-indicator-checked-bg__dt,
      "custom-control-indicator-checked-disabled-bg":          $custom-control-indicator-checked-disabled-bg__dt,
      "custom-control-indicator-checked-border-color":         $custom-control-indicator-checked-border-color__dt,
      "custom-control-indicator-focus-border-color":           $custom-control-indicator-focus-border-color__dt,
      "custom-control-indicator-active-color":                 $custom-control-indicator-active-color__dt,
      "custom-control-indicator-active-bg":                    $custom-control-indicator-active-bg__dt,
      "custom-control-indicator-active-border-color":          $custom-control-indicator-active-border-color__dt,
      "custom-checkbox-indicator-icon-checked":                $custom-checkbox-indicator-icon-checked__dt,
      "custom-checkbox-indicator-indeterminate-bg":            $custom-checkbox-indicator-indeterminate-bg__dt,
      "custom-checkbox-indicator-indeterminate-color":         $custom-checkbox-indicator-indeterminate-color__dt,
      "custom-checkbox-indicator-indeterminate-border-color":  $custom-checkbox-indicator-indeterminate-border-color__dt,
      "custom-checkbox-indicator-icon-indeterminate":          $custom-checkbox-indicator-icon-indeterminate__dt,
      "custom-radio-indicator-icon-checked":                   $custom-radio-indicator-icon-checked__dt,
      "custom-select-color":                                   $custom-select-color__dt,
      "custom-select-disabled-color":                          $custom-select-disabled-color__dt,
      "custom-select-bg":                                      $custom-select-bg__dt,
      "custom-select-disabled-bg":                             $custom-select-disabled-bg__dt,
      "custom-select-indicator-color":                         $custom-select-indicator-color__dt,
      "custom-select-indicator":                               $custom-select-indicator__dt,
      "custom-select-background":                              $custom-select-background__dt,
      "custom-select-border-color":                            $custom-select-border-color__dt,
      "custom-select-focus-border-color":                      $custom-select-focus-border-color__dt,
      "custom-range-track-bg":                                 $custom-range-track-bg__dt,
      "custom-range-thumb-bg":                                 $custom-range-thumb-bg__dt,
      "custom-range-thumb-active-bg":                          $custom-range-thumb-active-bg__dt,
      "custom-range-thumb-disabled-bg":                        $custom-range-thumb-disabled-bg__dt,
      "custom-file-focus-border-color":                        $custom-file-focus-border-color__dt,
      "custom-file-disabled-bg":                               $custom-file-disabled-bg__dt,
      "custom-file-color":                                     $custom-file-color__dt,
      "custom-file-bg":                                        $custom-file-bg__dt,
      "custom-file-border-color":                              $custom-file-border-color__dt,
      "custom-file-button-color":                              $custom-file-button-color__dt,
      "custom-file-button-bg":                                 $custom-file-button-bg__dt
    )
  )
);
