//
// CoreUI 3.x Color system
//

$gray-base__dt:  desaturate(#08091a, 10%) !default;
$gray-100__dt:   desaturate(#313243, 10%) !default;
$gray-200__dt:   desaturate(#2e2f40, 10%) !default;
$gray-300__dt:   desaturate(#2c2d3e, 10%) !default;
$gray-400__dt:   desaturate(#272839, 10%) !default;
$gray-500__dt:   desaturate(#242536, 10%) !default;
$gray-600__dt:   desaturate(#1f2031, 10%) !default;
$gray-700__dt:   desaturate(#1c1d2e, 10%) !default;
$gray-800__dt:   desaturate(#1a1b2c, 10%) !default;
$gray-900__dt:   desaturate(#151627, 10%) !default;

$grays__dt: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays__dt: map-merge(
  (
    "100":  $gray-100__dt,
    "200":  $gray-200__dt,
    "300":  $gray-300__dt,
    "400":  $gray-400__dt,
    "500":  $gray-500__dt,
    "600":  $gray-600__dt,
    "700":  $gray-700__dt,
    "800":  $gray-800__dt,
    "900":  $gray-900__dt
  ),
  $grays__dt
);

$primary__dt:    desaturate($primary, 20%) !default;
$secondary__dt:  desaturate($secondary, 20%) !default;
$success__dt:    desaturate($success, 20%) !default;
$info__dt:       desaturate($info, 20%) !default;
$warning__dt:    desaturate($warning, 20%) !default;
$danger__dt:     desaturate($danger, 20%) !default;
$light__dt:      $gray-700__dt !default;
$dark__dt:       $gray-900__dt !default;

$theme-colors__dt: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors__dt: map-merge(
  (
    "primary":    $primary__dt,
    "secondary":  $secondary__dt,
    "success":    $success__dt,
    "info":       $info__dt,
    "warning":    $warning__dt,
    "danger":     $danger__dt,
    "light":      $light__dt,
    "dark":       $dark__dt
  ),
  $theme-colors__dt
);

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark__dt:   $gray-base__dt !default;
$yiq-text-light__dt:  $white !default;

// stylelint-disable-next-line scss/dollar-variable-default
$yiq-theme-map: map-merge(
  $yiq-theme-map,
  (
    dark: (
      "yiq-text-dark":  $yiq-text-dark__dt,
      "yiq-text-light":  $yiq-text-light__dt
    )
  ),
);
