// Dark theme
$toast-background-color__dt:  $gray-100__dt !default;
$toast-color__dt:             $body-color__dt !default;
$toast-border-color__dt:      transparent !default;

$toast-header-color__dt:             $body-color__dt !default;
$toast-header-background-color__dt:  $gray-100__dt !default;
$toast-header-border-color__dt:      rgba($black, .2) !default;

// stylelint-disable-next-line scss/dollar-variable-default
$toast-theme-map: map-merge(
  $toast-theme-map,
  (
    dark: (
      "toast-background-color":         $toast-background-color__dt,
      "toast-color":                    $toast-color__dt,
      "toast-border-color":             $toast-border-color__dt,
      "toast-header-color":             $toast-header-color__dt,
      "toast-header-background-color":  $toast-header-background-color__dt,
      "toast-header-border-color":      $toast-header-border-color__dt
    )
  )
);
