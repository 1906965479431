// Dark theme

$sidebar-color__dt:                               $body-color__dt !default;
$sidebar-bg__dt:                                  $gray-400__dt !default;
$sidebar-borders__dt:                             (all: 0) !default;
$sidebar-brand-bg__dt:                            rgba($black, .2) !default;
$sidebar-header-bg__dt:                           rgba($black, .2) !default;
$sidebar-form-border__dt:                         0 !default;
$sidebar-form-bg__dt:                             rgba($black, .1) !default;
$sidebar-form-color__dt:                          $white !default;
$sidebar-form-placeholder-color__dt:              rgba($white, .7) !default;
$sidebar-nav-title-color__dt:                     rgba($white, .6) !default;
$sidebar-nav-link-color__dt:                      rgba($white, .8) !default;
$sidebar-nav-link-bg__dt:                         transparent !default;
$sidebar-nav-link-icon-color__dt:                 rgba($white, .6) !default;
$sidebar-nav-link-borders__dt:                    0 !default;
$sidebar-nav-link-hover-color__dt:                $white !default;
$sidebar-nav-link-hover-bg__dt:                   $primary__dt !default;
$sidebar-nav-link-hover-icon-color__dt:           $white !default;
$sidebar-nav-link-hover-borders__dt:              0 !default;
$sidebar-nav-link-active-color__dt:               $white !default;
$sidebar-nav-link-active-bg__dt:                  rgba($white, .05) !default;
$sidebar-nav-link-active-icon-color__dt:          $white !default;
$sidebar-nav-link-active-borders__dt:             0 !default;
$sidebar-nav-link-disabled-color__dt:             darken($white, 30%) !default;
$sidebar-nav-link-disabled-bg__dt:                $sidebar-bg__dt !default;
$sidebar-nav-link-disabled-icon-color__dt:        $sidebar-nav-link-icon-color__dt !default;
$sidebar-nav-link-disabled-borders__dt:           0 !default;
$sidebar-nav-dropdown-color__dt:                  $white !default;
$sidebar-nav-dropdown-bg__dt:                   rgba(0, 0, 0, .2) !default;
$sidebar-nav-dropdown-borders__dt:                0 !default;
$sidebar-nav-dropdown-indicator-color__dt:        rgba($white, .5) !default;
$sidebar-nav-dropdown-indicator__dt:              str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-color__dt}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-nav-dropdown-indicator-hover-color__dt:  $sidebar-nav-link-hover-color__dt !default;
$sidebar-nav-dropdown-indicator-hover__dt:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-hover-color__dt}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-nav-tabs-border-color__dt:               rgba($black, .2) !default;
$sidebar-nav-tabs-link-active-bg__dt:             rgba($white, .05) !default;
$sidebar-nav-tabs-link-active-border-color__dt:   rgba($black, .2) rgba($black, .2) $primary__dt !default;
$sidebar-nav-tabs-link-hover-bg__dt:              rgba($black, .2) !default;
$sidebar-nav-tabs-link-hover-border-color__dt:    rgba($black, .2) rgba($black, .2) $gray-200 !default;
$sidebar-tab-content-borders__dt:                 ( top: 1px solid rgba($black, .2) ) !default;
$sidebar-footer-bg__dt:                           rgba($black, .2) !default;
$sidebar-footer-borders__dt:                      0 !default;
$sidebar-minimizer-borders__dt:                   0 !default;
$sidebar-minimizer-bg__dt:                        rgba($black, .2) !default;
$sidebar-minimizer-indicator-color__dt:           rgba($white, .5) !default;
$sidebar-minimizer-indicator__dt:                 str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-indicator-color__dt}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-minimizer-hover-bg__dt:                rgba(0, 0, 0, .3) !default;
$sidebar-minimizer-hover-indicator-color__dt:     $sidebar-nav-link-hover-color__dt !default;
$sidebar-minimizer-hover-indicator__dt:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-hover-indicator-color__dt}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;

$sidebar-variants-map__dt: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sidebar-variants-map__dt: map-merge(
  (
    dark: (
      "sidebar-color":                               $sidebar-color__dt,
      "sidebar-bg":                                  $sidebar-bg__dt,
      "dark-theme-sidebar-bg":                       0,
      "sidebar-borders":                             $sidebar-borders__dt,
      "dark-theme-sidebar-borders":                  0,
      "sidebar-brand-bg":                            $sidebar-brand-bg__dt,
      "sidebar-header-bg":                           $sidebar-header-bg__dt,
      "sidebar-form-border":                         $sidebar-form-border__dt,
      "sidebar-form-bg":                             $sidebar-form-bg__dt,
      "sidebar-form-color":                          $sidebar-form-color__dt,
      "sidebar-form-placeholder-color":              $sidebar-form-placeholder-color__dt,
      "sidebar-nav-title-color":                     $sidebar-nav-title-color__dt,
      "sidebar-nav-link-color":                      $sidebar-nav-link-color__dt,
      "sidebar-nav-link-bg":                         $sidebar-nav-link-bg__dt,
      "sidebar-nav-link-icon-color":                 $sidebar-nav-link-icon-color__dt,
      "sidebar-nav-link-borders":                    $sidebar-nav-link-borders__dt,
      "sidebar-nav-link-hover-color":                $sidebar-nav-link-hover-color__dt,
      "sidebar-nav-link-hover-bg":                   $sidebar-nav-link-hover-bg__dt,
      "sidebar-nav-link-hover-icon-color":           $sidebar-nav-link-hover-icon-color__dt,
      "sidebar-nav-link-hover-borders":              $sidebar-nav-link-hover-borders__dt,
      "sidebar-nav-link-active-color":               $sidebar-nav-link-active-color__dt,
      "sidebar-nav-link-active-bg":                  $sidebar-nav-link-active-bg__dt,
      "sidebar-nav-link-active-icon-color":          $sidebar-nav-link-active-icon-color__dt,
      "sidebar-nav-link-active-borders":             $sidebar-nav-link-active-borders__dt,
      "sidebar-nav-link-disabled-color":             $sidebar-nav-link-disabled-color__dt,
      "sidebar-nav-link-disabled-bg":                $sidebar-nav-link-disabled-bg__dt,
      "sidebar-nav-link-disabled-icon-color":        $sidebar-nav-link-disabled-icon-color__dt,
      "sidebar-nav-link-disabled-borders":           $sidebar-nav-link-disabled-borders__dt,
      "sidebar-nav-dropdown-color":                  $sidebar-nav-dropdown-color__dt,
      "sidebar-nav-dropdown-bg":                     $sidebar-nav-dropdown-bg__dt,
      "sidebar-nav-dropdown-borders":                $sidebar-nav-dropdown-borders__dt,
      "sidebar-nav-dropdown-indicator-color":        $sidebar-nav-dropdown-indicator-color__dt,
      "sidebar-nav-dropdown-indicator":              $sidebar-nav-dropdown-indicator__dt,
      "sidebar-nav-dropdown-indicator-hover-color":  $sidebar-nav-dropdown-indicator-hover-color__dt,
      "sidebar-nav-dropdown-indicator-hover":        $sidebar-nav-dropdown-indicator-hover__dt,
      "sidebar-nav-tabs-border-color":               $sidebar-nav-tabs-border-color__dt,
      "sidebar-nav-tabs-link-active-bg":             $sidebar-nav-tabs-link-active-bg__dt,
      "sidebar-nav-tabs-link-active-border-color":   $sidebar-nav-tabs-link-active-border-color__dt,
      "sidebar-nav-tabs-link-hover-bg":              $sidebar-nav-tabs-link-hover-bg__dt,
      "sidebar-nav-tabs-link-hover-border-color":    $sidebar-nav-tabs-link-hover-border-color__dt,
      "sidebar-tab-content-borders":                 $sidebar-tab-content-borders__dt,
      "sidebar-footer-bg":                           $sidebar-footer-bg__dt,
      "sidebar-footer-borders":                      $sidebar-footer-borders__dt,
      "sidebar-minimizer-borders":                   $sidebar-minimizer-borders__dt,
      "sidebar-minimizer-bg":                        $sidebar-minimizer-bg__dt,
      "sidebar-minimizer-indicator-color":           $sidebar-minimizer-indicator-color__dt,
      "sidebar-minimizer-indicator":                 $sidebar-minimizer-indicator__dt,
      "sidebar-minimizer-hover-bg":                  $sidebar-minimizer-hover-bg__dt,
      "sidebar-minimizer-hover-indicator-color":     $sidebar-minimizer-hover-indicator-color__dt,
      "sidebar-minimizer-hover-indicator":           $sidebar-minimizer-hover-indicator__dt
    )
  ),
  $sidebar-variants-map__dt
);

$sidebar-default-variant__dt: "dark" !default;
$sidebar-variants__dt: (
  map:      $sidebar-variants-map__dt,
  default:  $sidebar-default-variant__dt
) !default;

// stylelint-disable-next-line scss/dollar-variable-default
$sidebar-theme-map: map-merge(
  $sidebar-theme-map,
  (
    dark:  $sidebar-variants__dt
  )
);
