// Links
//
// Style anchor elements.

$link-color__dt:        lighten($primary__dt, 12.5%) !default;
$link-hover-color__dt:  darken($link-color__dt, 15%) !default;

// stylelint-disable-next-line scss/dollar-variable-default
$link-theme-map: map-merge(
  $link-theme-map,
  (
    dark: (
      "link-color":        $link-color__dt,
      "link-hover-color":  $link-hover-color__dt
    )
  )
);
