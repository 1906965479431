// Body
//
// Settings for the `<body>` element.

$body-bg__dt:     $gray-base__dt !default;
$body-color__dt:  #e1e1e1 !default;

// stylelint-disable-next-line scss/dollar-variable-default
$body-theme-map: map-merge(
  $body-theme-map,
  (
    dark: (
      "body-bg":     $body-bg__dt,
      "body-color":  $body-color__dt
    )
  )
);
