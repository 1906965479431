// Breadcrumbs

// Dark theme

$breadcrumb-bg__dt:             transparent !default;
$breadcrumb-border-color__dt:   $component-bg__dt !default;
$breadcrumb-divider-color__dt:  $body-color__dt !default;
$breadcrumb-active-color__dt:   $gray-600__dt !default;

// stylelint-disable-next-line scss/dollar-variable-default
$breadcrumb-theme-map: map-merge(
  $breadcrumb-theme-map,
  (
    dark: (
      "breadcrumb-bg":             $breadcrumb-bg__dt,
      "breadcrumb-border-color":   $breadcrumb-border-color__dt,
      "breadcrumb-divider-color":  $breadcrumb-divider-color__dt,
      "breadcrumb-active-color":   $breadcrumb-active-color__dt
    )
  )
);
