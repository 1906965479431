// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
@import "variables/colors";
@import "variables/brand-colors";
@import "variables/gradients";

// Options
@import "variables/options";
@import "variables/spacing";
@import "variables/z-index";

@import "variables/body";
@import "variables/components/shared";
@import "variables/typography";
@import "variables/grid";
@import "variables/elevation";

// Components
@import "variables/components/links";
@import "variables/components/tables";
@import "variables/components/buttons-forms";
@import "variables/components/custom-forms";
@import "variables/components/navs";
@import "variables/components/navbar";
@import "variables/components/header";
@import "variables/components/subheader";
@import "variables/components/sidebar";
@import "variables/components/footer";
@import "variables/components/dropdowns";
@import "variables/components/pagination";
@import "variables/components/cards";
@import "variables/components/jumbotron";
@import "variables/components/tooltips";
@import "variables/components/popovers";
@import "variables/components/toasts";
@import "variables/components/badges";
@import "variables/components/modals";
@import "variables/components/alerts";
@import "variables/components/progress-bars";
@import "variables/components/list-group";
@import "variables/components/images";
@import "variables/components/breadcrumb";
@import "variables/components/carousel";
@import "variables/components/spinners";
@import "variables/components/avatars";
@import "variables/components/switches";
@import "variables/components/close";
@import "variables/components/code";

// Utilities
@import "variables/utilities";


// Printing
@import "variables/printing";

// Cursor
$cursor-disabled: default !default;

// Apps
@import "variables/apps/email";
